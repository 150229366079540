import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import StandardLayout from './layouts/standard.layout';
import { RoutesLookup } from './lookups/routes.lookup';
import HomePage from './pages/home.page';
import InfoPage from './pages/info.page';
import LandingPage from './pages/landing.page';
import Abandoned3dStreetPage from './pages/portfolio-pages/abandoned-3d-street.page';
import ApexRacingPage from './pages/portfolio-pages/apex-racing.page';
import BrawlGamePage from './pages/portfolio-pages/brawl-game.page';
import Clay3dModelsPage from './pages/portfolio-pages/clay-3d-models.page';
import CosmicCafeBrandingPage from './pages/portfolio-pages/cosmic-cafe-branding.page';
import CubeMazePage from './pages/portfolio-pages/cube-maze.page';
import FestivalThrashersPage from './pages/portfolio-pages/festival-thrashers.page';
import FestogoBrandingPage from './pages/portfolio-pages/festogo-branding.page';
import ForestLevelPage from './pages/portfolio-pages/forest-level.page';
import GameCentrePage from './pages/portfolio-pages/game-centre.page';
import GenArtEnergyCorePage from './pages/portfolio-pages/gen-art-energy-core.page';
import GenArtWarehouse from './pages/portfolio-pages/gen-art-warehouse.page';
import LightingDiorama from './pages/portfolio-pages/lighting-diorama';
import MobaDemo from './pages/portfolio-pages/moba-research-demo';
import PitvilleRender from './pages/portfolio-pages/pitville-render';
import ProjectSkyscraper from './pages/portfolio-pages/project-skyscraper';
import PuzzleUnityPage from './pages/portfolio-pages/puzzle-unity.page';
import SkirmishDungeonPage from './pages/portfolio-pages/skirmish-dungeon.page';
import SteampunkBuilding from './pages/portfolio-pages/steampunk-building';
import SteampunkSnowPage from './pages/portfolio-pages/steampunk-snow.page';
import TechartCryptingPage from './pages/portfolio-pages/techart-crypting.page';
import TechartLanternPage from './pages/portfolio-pages/techart-lantern.page';
import TechartVrCauldronPage from './pages/portfolio-pages/techart-vr-cauldron.page';
import TrafficJamPage from './pages/portfolio-pages/traffic-jam.page';
import TravelmarkPage from './pages/portfolio-pages/travelmark.page';
import VrBeginnerPage from './pages/portfolio-pages/vr-beginner.page';

import './sass/style.scss';

const {
  portfolioItems,
  landing,
  portfolio,
  info
} = RoutesLookup;

interface Page {
  route: string,
  component: React.FC,
  hidden?: boolean,
}

const landingPage: Page = {
  route: landing.route,
  component: LandingPage,
};
const corePages: Page[] = [
  landingPage,
  {
    route: portfolio.route,
    component: HomePage,
  },
  {
    route: info.route,
    component: InfoPage,
  },
];

const portfolioPages = [
  {
    route: portfolioItems.abandoned3dStreet.route,
    component: Abandoned3dStreetPage,
  },
  {
    route: portfolioItems.steampunkBuilding.route,
    component: SteampunkBuilding,
  },
  {
    route: portfolioItems.mobaDemo.route,
    component: MobaDemo,
  },
  {
    route: portfolioItems.pitvilleRender.route,
    component: PitvilleRender,
  },
  {
    route: portfolioItems.projectSkyscraper.route,
    component: ProjectSkyscraper,
  },
  {
    route: portfolioItems.lightingDiorama.route,
    component: LightingDiorama,
  },
  {
    route: portfolioItems.apexRacing.route,
    component: ApexRacingPage,
  },
  {
    route: portfolioItems.brawlGame.route,
    component: BrawlGamePage,
    hidden: true,
  },
  {
    route: portfolioItems.clayObjects.route,
    component: Clay3dModelsPage,
  },
  {
    route: portfolioItems.cosmicCafeBranding.route,
    component: CosmicCafeBrandingPage,
    hidden: true,
  },
  {
    route: portfolioItems.cubeMaze.route,
    component: CubeMazePage,
    hidden: true,
  },
  {
    route: portfolioItems.festivalThrashers.route,
    component: FestivalThrashersPage,
  },
  {
    route: portfolioItems.festogoBranding.route,
    component: FestogoBrandingPage,
    hidden: true,
  },
  {
    route: portfolioItems.forestLevel.route,
    component: ForestLevelPage,
    hidden: true,
  },
  {
    route: portfolioItems.gameCentre.route,
    component: GameCentrePage,
    hidden: true,
  },
  {
    route: portfolioItems.generativeArt.route,
    component: GenArtEnergyCorePage,
  },
  {
    route: portfolioItems.generativeArtWarehouse.route,
    component: GenArtWarehouse,
  },
  {
    route: portfolioItems.puzzleLevel.route,
    component: PuzzleUnityPage,
    hidden: true,
  },
  {
    route: portfolioItems.skirmishDungeon.route,
    component: SkirmishDungeonPage,
  },
  {
    route: portfolioItems.industrySnowyMountains.route,
    component: SteampunkSnowPage,
  },
  {
    route: portfolioItems.travelMark.route,
    component: TravelmarkPage,
    hidden: true,
  },
  {
    route: portfolioItems.vr.route,
    component: VrBeginnerPage,
  },
  {
    route: portfolioItems.trafficJam.route,
    component: TrafficJamPage,
  },
  {
    route: portfolioItems.vrCauldron.route,
    component: TechartVrCauldronPage,
  },
  {
    route: portfolioItems.visualCrypting.route,
    component: TechartCryptingPage,
  },
  {
    route: portfolioItems.lantern.route,
    component: TechartLanternPage,
  }
];
const pages = [
  ...corePages,
  ...portfolioPages,
];

const Index = () => {
  function createPage(page: Page, index: number): ReactElement<typeof Route> {
    const {
      component: Component,
      route,
    } = page;

    let element: ReactElement = <Component />;
    if (page.route !== landing.route) {
      element = <StandardLayout>
        {element}
      </StandardLayout>;
    }

    return (
      <Route
        key={`page-${index}`}
        path={route}
        element={element}
      />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {
          pages
            .filter((page) => !page.hidden)
            .map((page, index) => createPage(page, index))
        }
        {/* <Route path="*">
          {landingPage.component}
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
};
ReactDOM.render(<Index />, document.getElementById('app'));
