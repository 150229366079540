import React from 'react';
import Grid from '../components/grid/grid';
import GridRow from '../components/grid/grid-row';
import GridSquareLarge from '../components/grid/grid-squares/grid-square-large';
import GridSquareStandard from '../components/grid/grid-squares/grid-square-standard';
import AbandonedStreetImage from '../images/abandoned/abandoned_render.png';
import LightingDioramaImage from '../images/bedroom/bedroom_render.png';
//import CarnivalImage from '../images/carnival/carnival_level.png';
import CryptingRenderImage from '../images/crypting/crypting_key_gif.gif';
import GenArtRenderImage from '../images/genart/genart_gif.gif';
import LanternRenderImage from '../images/lantern/lantern_gif.gif';
import PitvilleImage from '../images/pitville/pitville_render1.png';
import MobaImage from '../images/research/moba_gameplay1.png';
import SteampunkImage from '../images/steampunk/steampunk_render.jpg';
import TrafficJamImage from '../images/traffic/traffic_gif.gif';
import VRRenderImage from '../images/vr/vr_gif.gif';
import VRCauldronRenderImage from '../images/vr/vr_gif_cauldron.gif';
import WarehouseImage from '../images/warehouse/warehouse_night1.png';
import { LayoutLookup } from '../lookups/layout.lookup';
//import PlaceholderImage from '../images/placeholder.jpg';
import { RoutesLookup } from '../lookups/routes.lookup';

const HomePage = () => {
  const {
    portfolioItems: {
      vr,
      vrCauldron,
      abandoned3dStreet,
      generativeArt,
      generativeArtWarehouse,
      lantern,
      trafficJam,
      steampunkBuilding,
      //festivalThrashers,
      lightingDiorama,
      mobaDemo,
      pitvilleRender,
      //projectSkyscraper,
      visualCrypting
    }
  } = RoutesLookup;

  return (
    <div>
      <BootstrapGutter>
        <b>Tech Art Breakdowns</b>
      </BootstrapGutter>
      <Grid>
        <div className="row">
          <GridRow>
            <GridSquareStandard
              link={vrCauldron.route}
              image={VRCauldronRenderImage}
              title={vrCauldron.title}
            />
            <GridSquareStandard
              link={visualCrypting.route}
              image={CryptingRenderImage}
              title={visualCrypting.title}
            />
            <GridSquareStandard
              link={lantern.route}
              image={LanternRenderImage}
              title={lantern.title}
            />
            {/* <GridSquareStandard
              link={trafficJam.route}
              image={TrafficJamImage}
              title={trafficJam.title}
            /> */}
          </GridRow>
        </div>
      </Grid>

      {/* // DIVIDING LINE */}
      <BootstrapGutter>
        <div className="separator">
          <div></div>
        </div>
      </BootstrapGutter>
      <BootstrapGutter><b>Full Projects</b></BootstrapGutter>

      {/* START OF PROJECTS SECTION */}
      <Grid>
        <div className="row">
          <GridRow>
            <div>
              <GridSquareLarge
                link={generativeArt.route}              
                image={GenArtRenderImage}
                title={generativeArt.title}
              />
              <GridRow>
                <GridSquareStandard
                  link={mobaDemo.route}
                  image={MobaImage}
                  title={mobaDemo.title}
                />
                <GridSquareStandard
                  link={lightingDiorama.route}
                  image={LightingDioramaImage}
                  title={lightingDiorama.title}
                />
              </GridRow>
            </div>
            <div>
              <GridRow>
                <GridSquareStandard
                  link={steampunkBuilding.route}
                  image={SteampunkImage}
                  title={steampunkBuilding.title}
                />
                <GridSquareStandard
                  link={abandoned3dStreet.route}
                  image={AbandonedStreetImage}
                  title={abandoned3dStreet.title}
                />
              </GridRow>
              <GridSquareLarge
                link={vr.route}
                image={VRRenderImage}
                title={vr.title}
              />
            </div>
          </GridRow>
        </div>
        <div className="row">
          <GridRow>
            <div>
              <GridSquareLarge
                link={generativeArtWarehouse.route}
                image={WarehouseImage}
                title={generativeArtWarehouse.title}
              />
              <GridRow>
                <GridSquareStandard
                  link={pitvilleRender.route}
                  image={PitvilleImage}
                  title={pitvilleRender.title}
                />
              </GridRow>
            </div>
            <div>
              <GridSquareLarge
                link={trafficJam.route}
                image={TrafficJamImage}
                title={trafficJam.title}
              />
              <GridRow>
                {/* <GridSquareStandard
                  link={projectSkyscraper.route}
                  image={PlaceholderImage}
                  title={projectSkyscraper.title}
                /> */}
              </GridRow>
            </div>
          </GridRow>
        </div>
      </Grid>
    </div>
  );
};

// TODO: Remove Bootstrap entirely, it's cumbersome to work with.
const BootstrapGutter: React.FC = ({ children }) => {
  return <div className="row">
    <div className={LayoutLookup.gutter.standard}>
      {children}
    </div>
  </div>;
};

export default HomePage;
