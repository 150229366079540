import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import CubemazePreviewOneImage from '../../images/cubemaze/cubemaze_preview_1.jpg';
import CubemazePreviewFourImage from '../../images/cubemaze/cubemaze_preview_4.jpg';
import CubemazeUnityOneImage from '../../images/cubemaze/cubemaze_unity.png';
import CubemazeUnityTwoImage from '../../images/cubemaze/cubemaze_unity_1.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const CubeMazePage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.cubeMaze.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Concept and Project Overview</h2>
        <p>This is a <b>work-in-progress</b> project; development is still ongoing and changes are still being made.</p>
        <p>
          This was a personal game project collaboration with a friend and game programming student, Sam Baker. We both
          worked on multiple areas of the project together however I took the reigns on the design side and Sam focused
          on the functionality and programming logic. The game concept was built on from a small task set during a lecture
          earlier this term (September 2018); essentially the player rolls a ball around a randomly generated 6-sided cube
          to try and collect as many gems as possible within a time limit.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CubemazePreviewOneImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CubemazePreviewFourImage}
          caption="These show the current build of the game with working movement map generation and lighting"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Assets and Lighting</h2>
        <p>I created the cube using 3DS Max so that we could have a unique shape and style to the cubes to give the impression they interlocked, I also used random colouring and shading to stylise the cubes faces.  Majority of the lighting was unity directional lights as well as post-processing effects on the main camera.</p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CubemazeUnityOneImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CubemazeUnityTwoImage}
        />
      </div>
    </div>
    <div className="padding-top-img text-center">
      <p>Catch Sam Baker on Twitter <a href="https://twitter.com/SxmBaker" target="_blank" rel="noreferrer">here</a></p>
    </div>
  </div>
);

export default CubeMazePage;
