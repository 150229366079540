import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import ForestGameImageTwo from '../../images/forest/forest_game.jpg';
import ForestGameImageOne from '../../images/forest/forest_game_1.jpg';
import ForestUnityImage from '../../images/forest/forest_unity.jpg';
import ForestUnityWireImage from '../../images/forest/forest_unity_wire.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const ForestLevelPage = () => (
  <div>
    <div className="row">
      <div className={`${LayoutLookup.gutter.standard} separator`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.forestLevel.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Level Overview</h2>
        <p>This is a <b>work-in-progress</b> project; development is still ongoing and changes are still being made.</p>
        <p>
          The brief for this project was to create a level prototype which would last approximately one minute on Unity.
          We would only be allowed to use Unity asset store downloads and they must be FREE for use as well. I selected
          a variety of textures and and prefabs to create an organic area for the level. As this is a work in progress
          the set design of the cabin and around the river area isn't yet complete but it will be the players task to
          try and rebuild the bridge so they can cross and escape the flaming forest approaching from the mountains.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ForestUnityImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ForestGameImageOne}
          caption="This shows the main gameplay area and the flaming forest in the hills"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Assets and Details</h2>
        <p>
          I tried to choose assets from the store which all suited the same theme and art style so the level would feel
          consistent; although it is still being worked on I feel the majority of the environment is now complete and I
          can start to focus on the gameplay aspects. I wanted to put a lot of detail around the level and make it
          realistic, here you can see a collapsed bridge which I sunk into the river and added pieces around the bank
          for greater effect.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ForestGameImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ForestUnityWireImage}
        />
      </div>
    </div>
  </div>
);

export default ForestLevelPage;
