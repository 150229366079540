export interface RouteLookup {
  route: string,
  title: string,
}

type PortfolioItem = 'abandoned3dStreet'
  | 'apexRacing'
  | 'brawlGame'
  | 'clayObjects'
  | 'cosmicCafeBranding'
  | 'cubeMaze'
  | 'festivalThrashers'
  | 'festogoBranding'
  | 'forestLevel'
  | 'gameCentre'
  | 'generativeArt'
  | 'generativeArtWarehouse'
  | 'industrySnowyMountains'
  | 'lantern'
  | 'puzzleLevel'
  | 'lightingDiorama'
  | 'skirmishDungeon'
  | 'steampunkBuilding'
  | 'toxicMushrooms'
  | 'trafficJam'
  | 'travelMark'
  | 'mobaDemo'
  | 'pitvilleRender'
  | 'projectSkyscraper'
  | 'visualCrypting'
  | 'vr'
  | 'vrCauldron'
  ;

export const RoutesLookup: {
  info: RouteLookup,
  landing: RouteLookup,
  portfolio: RouteLookup,
  portfolioItems: Record<PortfolioItem, RouteLookup>,
} = {
  info: {
    route: '/info',
    title: 'Info',
  },
  landing: {
    route: '/',
    title: 'Jack Tilling',
  },
  portfolio: {
    route: '/portfolio',
    title: 'Portfolio',
  },
  portfolioItems: {
    abandoned3dStreet: {
      route: '/abandoned-3d-street',
      title: 'Abandoned Street - 3D Modelling and Texturing',
    },
    mobaDemo: {
      route: '/moba-research-demo',
      title: 'MOBA Research Project - Unity Gameplay Demo',
    },
    pitvilleRender: {
      route: '/pitville-building-render',
      title: 'Pitville Building - 3D Modelling and Texturing',
    },
    projectSkyscraper: {
      route: '/project-skyscraper',
      title: 'Project Skyscraper - Unity HDRP Gameplay Demo',
    },
    apexRacing: {
      route: '/apex-racing',
      title: 'Apex Racing - Unity Prototype',
    },
    brawlGame: {
      route: '/brawl-game',
      title: 'Smash &amp; Bash - 2D Unity Party Game Prototype'
    },
    clayObjects: {
      route: '/clay-object-models',
      title: '3D Clay Model Assignment',
    },
    cosmicCafeBranding: {
      route: '/cosmic-cafe',
      title: 'Cosmic Cafe Branding'
    },
    cubeMaze: {
      route: '/cube-maze',
      title: 'The Cube Maze - 3D Unity Prototype'
    },
    festivalThrashers: {
      route: '/festival-thrashers',
      title: 'Festival Thrashers - 3D Unity Party Game',
    },
    festogoBranding: {
      route: '/festogo-branding',
      title: 'Festogo Website Branding',
    },
    forestLevel: {
      route: '/forest-level',
      title: 'Flaming Forest - 3D Unity Level Design (WIP)',
    },
    gameCentre: {
      route: '/game-centre',
      title: 'Gamecentre Branding',
    },
    generativeArt: {
      route: '/gen-art-energy-core',
      title: 'Generative Art - Energy Core',
    },
    generativeArtWarehouse: {
      route: '/gen-art-warehouse',
      title: 'Warehouse - Particle Generator',
    },
    industrySnowyMountains: {
      route: '/steampunk-snow',
      title: 'Steampunk Snowy Mountains - UE4 Level Design',
    },
    lantern: {
      route: '/lantern-breakdown',
      title: 'Lantern Fire Shader Breakdown',
    },
    puzzleLevel: {
      route: '/puzzle-level',
      title: 'Puzzle Level - 3D Unity Level Design',
    },
    lightingDiorama: {
      route: '/room-diorama-lighting-study',
      title: 'Lighting Diorama',
    },
    skirmishDungeon: {
      route: '/skirmish-dungeon',
      title: 'Skirmish Dungeon Prototype - 3D Unity Dungeon Crawler',
    },
    steampunkBuilding: {
      route: '/steampunk-building',
      title: 'Steampunk Building - HDRP Study',
    },
    toxicMushrooms: {
      route: '/toxic-mushrooms',
      title: '',
    },
    trafficJam: {
      route: '/traffic-jam',
      title: 'Traffic Jam - Brains Eden Game Jam',
    },
    travelMark: {
      route: '/travelmark',
      title: 'Travelmark Poster and Branding',
    },
    visualCrypting: {
      route: '/visual-crypting',
      title: 'Visual Crypting - Small Gameplay VFX',
    },
    vr: {
      route: '/vr-beginner',
      title: 'VR Beginner - Unity Learn Project',
    },
    vrCauldron: {
      route: '/breakdown-vr',
      title: 'Potion and Cauldron - Unity Shaders & VFX',
    }
  },
};
