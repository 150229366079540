import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import DungeonGameplayImage from '../../images/dungeon/dungeon_gameplay.jpg';
import DungeonImage from '../../images/dungeon/dungeon_jpg.jpg';
import DungeonMenuImage from '../../images/dungeon/dungeon_menu.jpg';
import DungeonUnityImage from '../../images/dungeon/dungeon_unity.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const SkirmishDungeonPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.skirmishDungeon.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Assignment Brief</h2>
        <p>
          I was asked to create a 3D game level in Unity, using the unity tools and techniques I was taught in the previous
          semester. The game level must demonstrate the use of triggers, opening and closing doors, collisions, and physics
          based reactions to events occurring in-game.  When the game is loaded, the user should be presented with a splash
          screen followed by a main menu that contains at least 3 options (Start Game, Options and Quit). One of these
          options must allow the modification of game settings.
        </p>
        <p>
          I was given a simple random walk maze generator as a starting point for the assignment. I took this and modified it
          by changing parts of the code and adding new additions. Other features of the game include enemies which follow a
          procedurally generated navigation mesh and a player with keyboard and click-to-move controls.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={DungeonImage}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={DungeonGameplayImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={DungeonMenuImage}
          caption="These show some of the gameplay as well as the Unity scene after a level has been generated"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Asset Creation</h2>
        <p>
          All of the assets used in the game were a combination of online textures and unity prefab shapes to create
          simple representations of swords, torches and other gameplay elements.  As this was a prototype, in a full
          sized game these would have been with replaced by fully detailed models and bespoke textures.
        </p>
        <p>
          The game has a handful of additional elements I created including scripts, animations, lighting and post-processing.
          I wanted to make the game look visually appealing for a prototype so a lot of time was spent making sure the
          lighting and special effects worked well in the small levels.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={DungeonUnityImage}
          applyImageShadow
        />
      </div>
    </div>
  </div>
);

export default SkirmishDungeonPage;
