import React from 'react';

interface PortfolioItemSketchfabProps {
  sketchfabId: string,
  caption?: string,
}

const PortfolioItemSketchfab = (props: PortfolioItemSketchfabProps) => {
  const {
    sketchfabId,
    caption,
  } = props;

  const url = `https://sketchfab.com/models/${sketchfabId}/embed`;

  return (
    <div className="text-center">
      <iframe
        title={url}
        width="90%"
        height="460px;"
        src={url}
        frameBorder="0"
        allow="autoplay; fullscreen; vr"
        // @ts-ignore
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      />
      {
        caption && <p>{caption}</p>
      }
    </div>
  );
};

export default PortfolioItemSketchfab;
