import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';

import LanternUnityImageTwo from '../../images/lantern/lantern_gif.gif';
import LanternUnityImageThree from '../../images/lantern/lantern_graph_png.png';
import LanternUnityImageOne from '../../images/lantern/lantern_graph_summary_png.png';

import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const TechartLanternPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.lantern.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Visual Crypting Lantern Breakdown</h2>
        <p>
          This scrolling fire shader was created with the idea in mind of feeling coherent with the other stylized vfx assets.  As to avoid using a gradient texture,
          the gradient mask used to blend the noise was created by combining multiple rotated gradients.  The grayscale result is then masked at different thresholds
          to get a layered fire effect. Below you can see a summary of the rough flow of the graph, created just for illustrative purposes using preview nodes at key steps 
          (The full graph can be found at the bottom of the page!).
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={LanternUnityImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={LanternUnityImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={LanternUnityImageThree}
        />
      </div>
    </div>
  </div>
);

export default TechartLanternPage;
