import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';

import VRCauldronUnityImageFour from '../../images/vr/vr_gif_cauldron.gif';
import VRCauldronUnityImageOne from '../../images/vr/vr_gif_potions.gif';
import VRCauldronUnityImageFive from '../../images/vr/vr_graph_cauldron.png';
import VRCauldronUnityImageSix from '../../images/vr/vr_graph_cauldron_2.png';
import VRCauldronUnityImageTwo from '../../images/vr/vr_graph_potion.png';
import VRCauldronUnityImageThree from '../../images/vr/vr_potion_smash_gif.gif';

import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

interface ContentLink {
  text: string,
  link: string,
}

const contentLinks: ContentLink[] = [
  {
    text: 'Unity Forums',
    link: 'https://forum.unity.com/threads/new-vr-beginner-escape-room.834769/',
  },
  {
    text: 'Asset Store',
    link: 'https://assetstore.unity.com/packages/essentials/tutorial-projects/vr-beginner-the-escape-room-163264',
  },
  {
    text: 'Unity Learn Project',
    link: 'https://learn.unity.com/project/vr-beginner-the-escape-room',
  },
];

const TechartVrCauldronPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.vrCauldron.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>VR Beginner Project Breakdown</h2>
        <p>
          Here I will breakdown some of my favourite effects I created ffor the VR Beginner Unity Project,
           you can see a more general project overview <a href="https://jacktilling.co.uk/vr-beginner" target="_blank" rel="noreferrer">here</a>.
        </p>
        <p>
          I was part of 4 main developers who worked in engine and took the project from concept to release. Although my
          focus was on designing and implementing visual effects and shaders, I was also involved with all aspects of
          the project, especially visuals, including: modelling, texturing, scripting, lighting and testing.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageOne}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Dynamic Potion Visuals</h2>
        <p>
          The potions were created to be dynamic interactive objects.  They have removable caps, self levelling & pourable liquid levels, and smashable glass.
          As we were creating for VR everything had to be really lightweight with as few textures as possible.  I did all of the liquid mechanics using a shader you can find below and
          a few simple particle systems for the pouring.  The smashable glass is just a secondary mesh broken into pieces with an instancing system to ensure performance.
          All of these along with audio and the shader are controlled with a c# script I created.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageThree}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Dynamic Cauldron Visuals</h2>
        <p>
          The cauldron was designed to have multiple speeds and parameters depending on the control board and the different items thrown in.
          I created a shader for the twirling effect taking advantage of the posterize node to ensure all my effects in the project felt a coherent style.
          The bubbles and smashes were created using VFX graph and use GPU events to instance the splashes when a bubble 'pops'; 
          This popping effect is achieved by using a shader to dissolve the bubble particles from the top down.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageFour}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageFive}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRCauldronUnityImageSix}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Content Links</h2>
        <p>Here you will find links to most of the content locations should you want some more info on the project or the team behind it.</p>
        {
          contentLinks.map((contentLink: ContentLink, index: number) => {
            const {
              link,
              text,
            } = contentLink;

            return (
              <p key={`content-link-${index}`}>
                <a
                  href={link}
                  target="_blank" rel="noreferrer"
                >
                  {text}
                </a>
              </p>
            );
          })
        }
      </div>
    </div>
  </div>
);

export default TechartVrCauldronPage;
