import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import CosmicCoasterImage from '../../images/cosmic/cosmic_coaster.png';
import InfoImage from '../../images/cosmic/cosmic_info.png';
import CosmicLogoImage from '../../images/cosmic/cosmic_logo.png';
import CosmicWebsiteImage from '../../images/cosmic/cosmic_website.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  CosmicLogoImage,
  CosmicWebsiteImage,
  CosmicCoasterImage,
];

const CosmicCafeBrandingPage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.cosmicCafeBranding.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className="col-xs-12 col-sm-5 offset-sm-1 col-lg-3 offset-lg-3">
        <h2>Infographic Design and Concept</h2>
        <p>
          This is a project from A-level graphic communications which saw me design the branding and infographic for a
          fake cafe which would focus on educational space designs to try and inform children on the solar system.
          The project was mainly built on Adobe Illustrator but also featured some composition editing on Photoshop.
          The logo is simple and was meant to capture people's attention quickly with the bright colours. However the
          main piece in this project was the infographic and each of the uniquely designed and coloured planets.
        </p>
      </div>
      <div className="col-xs-12 col-sm-5 offset-sm-1 col-lg-3 offset-lg-0">
        <PortfolioItemImage
          image={InfoImage}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Logo and Website Design</h2>
        <p>
          On top of the logo the project also allowed for a basic website design mock-up which would give you an example
          of what kind of style a real website can be. As an extra part of the project I also created a set of coasters
          which could eb used in a real world cafe as added branding.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: ImageType) => (
        <div
          key={`cosmic-cafe-footer-image-${image}`}
          className="row padding-top-img"
        >
          <div className={LayoutLookup.gutter.standard}>
            <PortfolioItemImage
              image={image}
            />
          </div>
        </div>
      ))
    }
  </div>
);

export default CosmicCafeBrandingPage;
