import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PortfolioItemSketchfab from '../../components/portfolio-items/portfolio-item-sketchfab';
import AbandonedImage from '../../images/abandoned/abandoned_render.png';
import PosterImage from '../../images/abandoned/poster_sheet_2.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const Abandoned3dStreetPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.abandoned3dStreet.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Scene Design Posters</h2>
        <br />
        <p>I was tasked with creating a 3D scene around the theme of lost treasure; I chose to take a unique approach and have an abandoned city as my setting and have a special weapon as the lost treasure (Similar to how they are represented in video games).  The first part of the submission was to render a series of posters which show the the 3D scene, these can include clay, wireframes and textures.  I achieved an A overall for this assignment during the second semester of my first year studying Games Design at University.</p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={AbandonedImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PosterImage}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <p className="text-center">This shows the three posters of the scene</p>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2 className="padding-bottom">Sketchfab Interactive Upload</h2>
        <p>
          The scene was created using <i>Autodesk 3DS Max</i> and each object was UV'd to fit onto individual 4 texture
          sheets. I then took each individual model into <i>Substance Painter</i> and hand textured them using a combination
          of pre-made materials and other substance tools. Below you can view the final scene on Sketchfab.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemSketchfab
          sketchfabId="4d26dd78cacd4948bb83ba34c73c4bdd"
          caption="Final scene fully textured"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemSketchfab
          sketchfabId="a2ef5be7a98c4eb1ae400587b041ca11"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemSketchfab
          sketchfabId="29991688d9be451dbc2072a31cbb5cca"
          caption="Few props from inside the scene"
        />
      </div>
    </div>
  </div>
);

export default Abandoned3dStreetPage;
