import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';

import CryptingUnityImageFive from '../../images/crypting/crypting_gate_gif.gif';
import CryptingUnityImageThree from '../../images/crypting/crypting_key_gif.gif';
import CryptingUnityImageOne from '../../images/crypting/crypting_leaves_gif.gif';
import CryptingUnityImageTwo from '../../images/crypting/crypting_leaves_png.jpeg';
import CryptingUnityImageFour from '../../images/crypting/crypting_smoke_gif.gif';

import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

interface ContentLink {
  text: string,
  link: string,
}

const contentLinks: ContentLink[] = [
  {
    text: 'Asset Store',
    link: 'https://assetstore.unity.com/packages/essentials/tutorial-projects/unity-s-visual-scripting-tutorial-192201',
  },
  {
    text: 'Unity Learn Project',
    link: 'https://learn.unity.com/project/visual-scripting-application-clive-the-cat-s-visual-crypting',
  },
];

const TechartCryptingPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.visualCrypting.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Visual Crypting Environmental Effects</h2>
        <p>
          This was a very small project which required a combination of small lightweight effect which could run on all devices. 
          All of the textures/noise used through out were very small (or procedural) to keep the build super lightweight and platform agnostic.
          For the environment I created some atmospheric effects, this included wind, fireflies, falling leaves and a very simple 'wobble' shader for the 
          layered bushes and fake overhead tree cutouts.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CryptingUnityImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CryptingUnityImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Gameplay & Polish Effects</h2>
        <p>
          These effects hoped to assist in explaining the gameplay purpose to the player.  For example the simple tiling glow shader on objects and the sparkle
          around interactable objects like the key. The rubble around the gate hopes to draw the users attention to indicate a change in the map. 
          The dust around the cats feet, although not essential for gameplay, is a small touch that helps to sell the surroundings! 
          Most of these were simple Shuriken Particle Systems which could be called from the visual scripting portion of the project.
        </p>
      </div>
    </div>
    
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CryptingUnityImageThree}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CryptingUnityImageFour}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CryptingUnityImageFive}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Content Links</h2>
        <p>Here you will find links to most of the content locations should you want some more info on the project:</p>
        {
          contentLinks.map((contentLink: ContentLink, index: number) => {
            const {
              link,
              text,
            } = contentLink;

            return (
              <p key={`content-link-${index}`}>
                <a
                  href={link}
                  target="_blank" rel="noreferrer"
                >
                  {text}
                </a>
              </p>
            );
          })
        }
      </div>
    </div>
  </div>
);

export default TechartCryptingPage;
