import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import TravelAppImage from '../../images/travel/travel_app.png';
import TravelLogoImage from '../../images/travel/travel_logo.jpg';
import TravelPosterImage from '../../images/travel/travel_poster.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  TravelLogoImage,
  TravelPosterImage,
  TravelAppImage,
];

const TravelmarkPage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.travelMark.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Brand Design</h2>
        <p>
          Travelmark is a fake travelling comparison app which I created for my final end of year exam for my A-level in
          graphic communications. I created a brand through posters and a clean professional logo. The main focus of the
          project was the posters and their representation of real world landmarks, but I also created an application
          interface which would serve as a review process for landmarks.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: ImageType) => (
        <div
          key={`travelmark-image-${image}`}
          className="row padding-top-img"
        >
          <div className={LayoutLookup.gutter.standard}>
            <PortfolioItemImage
              image={image}
            />
          </div>
        </div>
      ))
    }
  </div>
);

export default TravelmarkPage;
