import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import ApexPowerupImage from '../../images/apexRacing/apex_powerup.png';
import ApexImageTwo from '../../images/apexRacing/apex_unity.png';
import ApexImageOne from '../../images/apexRacing/apex_unity_1.png';
import ApexImageThree from '../../images/apexRacing/apex_unity_2.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const ApexRacingPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.apexRacing.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Concept and Project Overview</h2>
        <p>The brief for this group project was to create a game prototype based on the racing genre.  This is where we decided on Apex Racing being a fast paced arcade game which we felt would be appropriate for the assignment but still allowed for a lot of creative freedom.</p>
        <p>As this was a group project the work was spread between 4 people but because I was the only designer my role was lead designer and technical artist.  This included brainstorming all the main gameplay concepts and laying out a plan for the term; on top of this I had to create and texture all 3D models and 2D textures/sprites.  This gave me a good handle of the workflow which might take place within a small games studio.  To finish the game and clean the visuals so the game had a coherent aesthetic I was tasked with being the technical artist, I really enjoyed this role and it allowed me to control lighting and visual effects for the whole project. </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ApexImageOne}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Assets and Lighting</h2>
        <p>I created all of the 3D modelled power-ups for the game as well as 2D renders for UI representation.  My next focus was lighting and this went hand in hand with the post-processing effects and the aurora shaders given to us by a member of the unity community.</p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ApexImageTwo}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ApexPowerupImage}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={ApexImageThree}
          applyImageShadow
        />
      </div>
    </div>
  </div>
);

export default ApexRacingPage;
