import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import BrawlGameImage from '../../images/brawl/brawl_game.jpg';
import BrawlUnityImage from '../../images/brawl/brawl_unity.jpg';
import BrawlUnityAnimationImage from '../../images/brawl/brawl_unity_anim.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const BrawlGamePage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.brawlGame.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Project Overview</h2>
        <p>
          This was my first project involving the Unity 2D package, it consisted of creating a party game which had
          multiplayer support. The main concept of the game was to have four players battle and attempt to knock each
          other off of a 2D arena and be the last player standing; players can also collect lightning bolts to boost
          the power of their punch.
        </p>
        <p>
          I enjoyed this project because it enabled me to create my own sprites and animations. The gameplay features
          include a punching combat system, health, double jumping and a power-up system.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={BrawlGameImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={BrawlUnityImage}
          caption="This shows the gameplay area and the unity scene view"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Game Assets and Animations</h2>
        <p>
          This was the first time I had created my own sprites and used the Unity animation nodes.
          This was very useful experience and since this project my skills have significantly improved.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={BrawlUnityAnimationImage}
        />
      </div>
    </div>
  </div>
);

export default BrawlGamePage;
