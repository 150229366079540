import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PortfolioItemYoutubeVideo from '../../components/portfolio-items/portfolio-item-youtube-video';
import GameplayStill2Image from '../../images/research/moba_editor.png';
import GameplayStill1Image from '../../images/research/moba_gameplay1.png';
import GameplayStill3Image from '../../images/research/moba_vfx.png';
import PosterImage from '../../images/research/research_poster.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const MobaDemo= () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.mobaDemo.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Research Project</h2>
        <p>
          For my final year of university I chose to research an area which I found intriguing and interesting.  The write up element
          consisted of researching and exploring the following statement:
        </p>
        <p><i>
          An investigation into how visual effects can be used effectively to convey combat gameplay within eSports
        </i></p>
        <p>
          After the report was submitted, the second stage of the assignment was to create a practical project and highlight portions
          of this demo and research with a academic poster.  The practical project I created was a MOBA-style combat system demo which
          enabled me to showcase visual effects and take into account many of the areas I explored during the write up.  This required me to
          take advantage of many different skills including shader graph, vfx graph, scripting, ui and sprite design and implementation.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemYoutubeVideo
          description="University Research Project - MOBA Style Unity Visual Game Demo"
          youtubeVideoId="xuRpl0i2720"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PosterImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={GameplayStill1Image}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={GameplayStill2Image}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={GameplayStill3Image}
        />
      </div>
    </div>     
  </div>
);

export default MobaDemo;
