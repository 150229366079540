import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PortfolioItemYoutubeVideo from '../../components/portfolio-items/portfolio-item-youtube-video';
import CarnivalDesignTemplateImage from '../../images/carnival/carnival_designtemplate.jpg';
import CarnivalEnvironmentImageTwo from '../../images/carnival/carnival_env.png';
import CarnivalEnvironmentImageOne from '../../images/carnival/carnival_env1.png';
import CarnivalEnvironmentImageThree from '../../images/carnival/carnival_env2.png';
import CarnivalLevelImageOne from '../../images/carnival/carnival_level.png';
import CarnivalGameImageOne from '../../images/carnival/carnival_level.png';
import CarnivalLevelImageTwo from '../../images/carnival/carnival_level1.png';
import CarnivalGameImageTwo from '../../images/carnival/carnival_level1.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

interface PortfolioLinkProps {
  name: string,
  link?: string,
}

const portfolioLinks: PortfolioLinkProps[] = [
  {
    name: 'Chloe Bostock',
    link: 'https://Chloebostock.wixsite.com/portfolio',
  },
  {
    name: 'Kieran Bond',
  },
  {
    name: 'Nick Wiltshire',
    link: 'https://www.artstation.com/nickwiltshire',
  },
  {
    name: 'Tom Toye',
    link: 'http://tomtoye.co.uk/',
  },
  {
    name: 'Jake Ivany',
  },
];

const FestivalThrashersPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.festivalThrashers.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Project Overview and Lore Pow Introduction</h2>
        <p>
          This is a large group project being worked on by multiple people, together we make up <a href="https://twitter.com/Lore_Pow" target="_blank" rel="noreferrer">Lore Pow</a>;
          an ambitious student game dev team currently working on Festival Thrashers for some of the members final year
          game dev project. I was interviewed by members of the team (final year students) as part of my second year
          module as a (fictional) contractor, my time with the team has officially finished however they have invited me
          to continue working as an official member of the team around my studies.
        </p>
        <p>
          I am currently working as a junior designer completing a range of tasks including lighting within engine to
          modelling and texturing assets for the environment. Festival Thrashers is a 4 player local party game where
          players have to reach the end of the level by dodging traps and knocking each other off the map to earn points.
          The game is still in early development but remains fun and engaging and so far user feedback has been really
          impressive.
        </p>
        <p>Here is a trailer created for the latest build of the game.</p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemYoutubeVideo
          description="Festival Thrashers Trailer"
          youtubeVideoId="UkcoerP0lks"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Level Design and Lighting</h2>
        <p>
          My first set of tasks involved creating early designs and later whiteboxes of the gameplay levels which eventually
          evolved and can now to be seen to be populated by props and other environment assets. My initial response was to
          create a template document which uses a grid to snap the traps and this is echoed within engine. I worked on these
          levels with the primary level designer and project leader Chloe.
        </p>
        <p>
          Another part of project I focused on leading up to the tech demo was creating a simple lighting and skybox system
          which would work well with the stylized graphic assets to initially present the game. This will be developed over
          the course of the next sprint.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalDesignTemplateImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalLevelImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalLevelImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Asset Creation and Texturing</h2>
        <p>
          Although the majority of objects and props had already been modelled before I joined the project, nothing had
          been textured; So me and lead artist on the project, Nick, worked on refining a substance painter pipeline to
          keep the texturing consistent. Some objects like the balloons and tents have custom masks which I created using
          RGB layering so that Nick could use a shader within Unity to add variation to each instance.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalEnvironmentImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalEnvironmentImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalEnvironmentImageThree}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Gameplay and Visual Effects</h2>
        <p>
          Along with creating some assets and textures, I worked within the Unity project to make a carnival confetti
          particle system which I then developed into a confetti cannon using bursts. I also had input into different
          aspects of the gameplay regarding flow and trap usage around each of the maps.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemYoutubeVideo
          description="Festival Thrashers Demo"
          youtubeVideoId="84Z8rchrGM4"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalGameImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={CarnivalGameImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Lore Pow Team</h2>
        <p>These are the other members of the Lore Pow team, for more information on them, check out their portfolios!</p>
        {
          portfolioLinks.map((portfolioLink: PortfolioLinkProps) => <PortfolioLink key={portfolioLink.name} {...portfolioLink} />)
        }
      </div>
    </div>
  </div>
);

const PortfolioLink = (props: PortfolioLinkProps) => {
  const {
    link,
    name,
  } = props;

  const nameElement = <p>{name}</p>;

  return (
    <div>
      {
        link
          ? (
            <a
              href={link}
              target="_blank" rel="noreferrer"
            >
              {nameElement}
            </a>
          )
          : nameElement
      }
    </div>
  );
};

export default FestivalThrashersPage;
