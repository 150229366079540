import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PuzzleGameImageTwo from '../../images/puzzle/puzzle_1.jpg';
import PuzzleGameImageOne from '../../images/puzzle/puzzle_game.jpg';
import PuzzleUnityImage from '../../images/puzzle/puzzle_unity.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const PuzzleUnityPage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.puzzleLevel.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Project Overview</h2>
        <p>
          This was a simple assignment to introduce me to the Unity game engine and C# scripting as I didn't have any
          previous experience. I had to showoff multiple mechanics including moving objects, physics based reactions
          and a moving player. The focus of this project was on scripting and functionality not artistic values so the
          majority of the time was spent scripting the mechanics.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PuzzleUnityImage}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PuzzleGameImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PuzzleGameImageTwo}
          caption="This shows one of the views of the puzzles, the objects move and you have to dodge them and reach the end to unlock the next door"
        />
      </div>
    </div>
  </div>
);

export default PuzzleUnityPage;
