import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PortfolioItemYoutubeVideo from '../../components/portfolio-items/portfolio-item-youtube-video';

import VRRenderImage from '../../images/vr/vr_gif.gif';
import VRUnityImageOne from '../../images/vr/vr_render_1.png';
import VRUnityImageTwo from '../../images/vr/vr_render_2.png';
import VRUnityImageThree from '../../images/vr/vr_render_3.png';

import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

interface ContentLink {
  text: string,
  link: string,
}

const contentLinks: ContentLink[] = [
  {
    text: 'Unity Forums',
    link: 'https://forum.unity.com/threads/new-vr-beginner-escape-room.834769/',
  },
  {
    text: 'Asset Store',
    link: 'https://assetstore.unity.com/packages/essentials/tutorial-projects/vr-beginner-the-escape-room-163264',
  },
  {
    text: 'Unity Learn Project',
    link: 'https://learn.unity.com/project/vr-beginner-the-escape-room',
  },
];

const VrBeginnerPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.vr.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Project Overview</h2>
        <p>
          This project was created by the content team at Unity during which I was completing my internship as a technical
          artist. This project is a short VR experience which helps beginner developers start to create and understand
          developing for virtual reality, you can find the learning content on <a href="https://learn.unity.com/project/vr-beginner-the-escape-room" target="_blank" rel="noreferrer">Unity Learn</a>.
        </p>
        <p>
          I was part of 4 main developers who worked in engine and took the project from concept to release. Although my
          focus was on designing and implementing visual effects and shaders, I was also involved with all aspects of
          the project, especially visual, including: modelling, texturing, scripting, lighting, testing and most importantly
          creating a fun and engaging project. I also, with guidance and feedback, directed and edited the trailer which
          can be found on all of Unity's social platforms.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={`${LayoutLookup.gutter.standard} text-center`}>
        <PortfolioItemYoutubeVideo
          description="VR Beginner | The Escape Room Video"
          youtubeVideoId="RkGHadlkjZQ"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRRenderImage}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Visual Effects and Lighting</h2>
        <p>
          As I was the only technical artist on the project, all of the effects were created and implemented by me. The
          systems used include Shuriken, Visual effect graph and Shader graph, these were all interests of mine prior to
          this project so I was keen to improve my skills and this project absolutely allowed me to do that. As the
          project had to be very lightweight for distribution within schools, I created all the effects to take advantage
          of procedural noise and other math operations such as step and posterize so I could avoid heavy textures (which
          we echoed in the models and textures throughout the rest of the project). This also allowed me to keep the theme
          consistent and stylized.
        </p>
        <p>
          I also completed a lot of the initial lighting passes, but this was a very iterative process between me and the
          team's creative director, as well as working on finalizing and optimisation with other members of the team.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRUnityImageOne}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRUnityImageTwo}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Prototype Level Assets</h2>
        <p>
          The tutorials walk the user through creating there own VR interactions using assets we provided. These were
          distributed between the artists with my major contribution being the fusebox you can see below and the
          environment setup. This texturing was all completed in Substance Painter and to keep the level of detail
          consistent a good portion of the assets were given to me to have a final pass of detail.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={VRUnityImageThree}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Content Links</h2>
        <p>Here you will find links to most of the content locations should you want some more info on the project or the team behind it.</p>
        {
          contentLinks.map((contentLink: ContentLink, index: number) => {
            const {
              link,
              text,
            } = contentLink;

            return (
              <p key={`content-link-${index}`}>
                <a
                  href={link}
                  target="_blank" rel="noreferrer"
                >
                  {text}
                </a>
              </p>
            );
          })
        }
      </div>
    </div>
  </div>
);

export default VrBeginnerPage;
