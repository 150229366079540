import React from 'react';
import { LayoutLookup } from '../lookups/layout.lookup';

const InfoPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`separator_1 ${LayoutLookup.gutter.standard}`}>
          <div className="row">
            <div className="col-xs-12 col-sm-8 offset-sm-4 col-lg-6 offset-lg-6">
              <h2>I'm Jack Tilling, a 3D Technical Artist currently working on Unity's Learn Content Team</h2>
              <p>
                I am an enthusiastic creative with a passion for design and I pride myself in being a committed
                individual and I never shy away from an endeavor. Whether I am working on realtime 3D content or drafting content with an awesome team, 
                this paired with my passion for games, visuals and their process of creation means I am always looking to further my skills in the realtime 3D space.
              </p>
              <p>
                I would love to get involved with new projects so please feel free to contact me via email <a href="mailto: jack.tilling43@hotmail.com" target="_blank" rel="noreferrer">Here</a>.
                A great way to contact me besides e-mail is via <a href="https://www.linkedin.com/in/jack-tilling-257925168/" target="_blank" rel="noreferrer">LinkedIn</a> or <a href="https://twitter.com/JackTilling_" target="_blank" rel="noreferrer">Twitter</a>;
              </p>
              <b></b>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`separator_2 ${LayoutLookup.gutter.standard}`}>
        </div>
      </div>
      <div className="row">
        <div className={`separator_3 ${LayoutLookup.gutter.standard}`}>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
