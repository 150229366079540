import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import SnowFullscreenImage from '../../images/snow/snow_fullscreen.jpg';
import SnowGameplayImage from '../../images/snow/snow_gameplay.jpg';
import SnowWidescreenImage from '../../images/snow/snow_widescreen2.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  SnowWidescreenImage,
  SnowGameplayImage,
  SnowFullscreenImage,
];

const SteampunkSnowPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.industrySnowyMountains.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Level Concept and Project Overview</h2>
        <p>
          The brief for this individual project was to create a short level which should take approximately one minute to
          complete. All assets for this assignment must come from the UE4 asset store; because of the excellent snow and
          rustic city assets I decided to move forward with the steampunk theme.
        </p>
        <p>
          The gameplay mechanics mostly consisted of player/keyboard activated triggers which helped me develop my
          blueprint system skills within UE4.  Other focussed features of the level include snow particles, post-processing,
          lighting and other steam visual effects.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: ImageType) => (
        <div
          key={`steampunk-snow-image-${image}`}
          className="row padding-top-img"
        >
          <div className={LayoutLookup.gutter.standard}>
            <PortfolioItemImage
              image={image}
            />
          </div>
        </div>
      ))
    }
  </div>
);

export default SteampunkSnowPage;
