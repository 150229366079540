import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import PortfolioItemSketchfab from '../../components/portfolio-items/portfolio-item-sketchfab';
import PosterImageOne from '../../images/clay/poster_sheet.jpg';
import PosterImageTwo from '../../images/clay/poster_sheet_1.jpg';
import SpaceshipRenderImage from '../../images/clay/spaceship_render.png';
import Vehicle3DImage from '../../images/clay/vehicle3d_jpg.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const Clay3dModelsPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.clayObjects.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Models Design Brief</h2>
        <p>
          The brief was to model a selection of 6 objects chosen by the lecturers in 3DS Max. Then render a series of
          images for each object including clay and wireframes, compile these each on their own A3 portfolio poster design.
          Each poster should be created in Photoshop, and includes a title, border theme and student details. An
          appropriate amount of detail needs to be kept throughout, with at least 6 posters showing different 3D models.
          Each of the model categories had a polygon count which had to be followed, the more complex the model the higher
          the polygon count allowed.  I received an A grade for this particular module during my first year of Games
          Design at University.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={SpaceshipRenderImage}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={Vehicle3DImage}
          caption="This shows the vehicle being created in Autodesk 3DS Max"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Poster Designs</h2>
        <p>
          The next set of images are the final poster designs which had to be submitted alongside the online model links.
          These were used to show off the model quickly and display some of the wireframe.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PosterImageOne}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={PosterImageTwo}
          applyImageShadow
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Sketchfab Interactive Uploads</h2>
        <p>
          Each of the models were uploaded to Sketchfab for submission and can be viewed below; we were allowed to use
          post-processing and lighting effects to enhance our models.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="e8bc4d7031594d4689e6803a5ab37ae0"
          caption="Futuristic Space Ship (Clay)"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="a42bcbb45c2141e09be1b9f8664f4738"
          caption="Log Cabin (Clay)"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="ac5d64753bd34da6b5504ec9d43072be"
          caption="Venus Fly Trap (Clay)"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="b4ba1c0ab3864415bb175919f7224aac"
          caption="Destiny Inspired Futuristic Sword (Clay)"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="7a81ee73fcd741aeaa9df129bee300be"
          caption="Old Antique Mantle Clock (Clay)"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemSketchfab
          sketchfabId="1e309b4214bd4ccba1927521f3428c0f"
          caption="Wild West Whisky Bottle (Clay)"
        />
      </div>
    </div>
  </div>
);

export default Clay3dModelsPage;
