import React from 'react';
import Footer from '../components/core/footer/footer';
import Header from '../components/core/header/header';
import SocialBanner from '../components/core/social-banner/social-banner';

const StandardLayout: React.FC = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        {children}
      </div>
      <SocialBanner />
      <Footer />
    </div>
  );
};

export default StandardLayout;
