import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import BuildingImage1 from '../../images/pitville/pitville_render1.png';
import BuildingImage2 from '../../images/pitville/pitville_render2.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const PitvilleRender= () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.pitvilleRender.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Realistic Building Renders</h2>
        <p>
          Realistic render of a university campus building for a mid-term assignment.  Built to scale using 3DS Max, Maya and rendered in Unity.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={BuildingImage1}
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={BuildingImage2}
        />
      </div>
    </div> 
  </div>
);

export default PitvilleRender;
