import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { LayoutLookup } from '../../../lookups/layout.lookup';
import {
  RouteLookup,
  RoutesLookup,
} from '../../../lookups/routes.lookup';

import './header.scss';

const pages: RouteLookup[] = [
  RoutesLookup.portfolio,
  RoutesLookup.info,
];

const Header = () => {
  const location = useLocation();

  return (
    <div>
      <div className="header">
        <div className="container-fluid">
          <div className="row">
            <div className={LayoutLookup.gutter.standard}>
              <div className="navbar-custom">
                <Link to="/" id="logo">
                  <span>Jack</span>
                  <span className="bold">Tilling</span>
                </Link>
                <nav>
                  <b>3D Technical Artist</b>
                  <b>|</b>
                  {
                    pages.map((page: RouteLookup, index: number) => {
                      const {
                        route,
                        title,
                      } = page;
                      
                      const isActiveClass: string = location.pathname === route ? 'active' : '';
                      return (
                        <Link
                          key={`header-link-${index}`}
                          to={route}
                          className={isActiveClass}
                        >
                          {title}
                        </Link>
                      );
                    })
                  }
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className={LayoutLookup.gutter.standard}>
            <div className="separator">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
