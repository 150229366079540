import React from 'react';
import { Link } from 'react-router-dom';
import ImageType from '../../../typings/image.type';

import './../grid.scss';

interface GridSquareProps extends BaseGridSquareProps {
  gridSquareSize: 'grid-square-large' | 'grid-square',
}

export interface BaseGridSquareProps {
  image: ImageType,
  link: string,
  title: string,
}

// Do not use this class unless being composed within another grid-square-WRAPPER class i.e. grid-square-large or grid-square-standard
const GridSquare = (props: GridSquareProps) => {
  const {
    gridSquareSize,
    image,
    link,
    title,
  } = props;

  const selector = 'grid-square';
  return (
    <Link to={link}>
      <div className={gridSquareSize}>
        <div
          className={`${selector}-image`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={`${selector}-overlay`}>
            <div className={`${selector}-hidden-content`}>
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default GridSquare;
