import React from 'react';
import './../grid.scss';
import GridSquare, { BaseGridSquareProps } from './grid-square';

const GridSquareStandard = (props: BaseGridSquareProps) => {
  const {
    link,
    title,
    image,
  } = props;

  return (
    <GridSquare
      gridSquareSize="grid-square"
      link={link}
      title={title}
      image={image}
    />
  );
};

export default GridSquareStandard;
