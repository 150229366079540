import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import GenArtGif from '../../images/genart/genart_gif.gif';
import GenArtRenderImageOne from '../../images/genart/genart_render.png';
import GenArtRenderImageTwo from '../../images/genart/genart_render_1.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  GenArtGif,
  GenArtRenderImageOne,
  GenArtRenderImageTwo,
];

const GenArtEnergyCorePage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.generativeArt.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Unity Visual Effect Graph</h2>
        <p>
          This project was a ton of fun and essentially consists of a small experiment which builds on a lot of visual
          effect graph experience I have gained during my time at Unity.  The particles simulate at runtime and take
          advantage of Unity's HDRP and volumetric lighting as well as post processing and other camera techniques.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: ImageType) => (
        <div
          key={`gen-art-energy-core-${image}`}
          className="row padding-top-img"
        >
          <div className={`${LayoutLookup.gutter.standard} text-center`}>
            <PortfolioItemImage image={image} />
          </div>
        </div>
      ))
    }
  </div>
);

export default GenArtEnergyCorePage;
