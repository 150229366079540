import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import LightingDioramaImage from '../../images/bedroom/bedroom_render.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const LightingDiorama= () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.lightingDiorama.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Unity Renders</h2>
        <p>
          A small afternoon study on baked lighting shortly after the release of Unity's universal render pipeline 
          (then called the lightweight render pipeline). Simple objects and materials created in 3DS Max. 
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={LightingDioramaImage}
        />
      </div>
    </div>
  </div>
);

export default LightingDiorama;
