import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesLookup } from '../lookups/routes.lookup';

import './landing-page.scss';

const LandingPage = () => {
  const lineDirections: string[] = [
    'right',
    'top',
    'left',
    'bottom',
  ];

  return (
    <div className="landing">
      <div className="container-fluid">
        <div className="row-landing">
          <h1>Jack Tilling</h1>
          <section className="animated-border-button large">
            <Link to={RoutesLookup.portfolio.route}>
              <span className="text">
                View Portfolio
                <ChevronRightIcon />
              </span>
              {
                lineDirections.map((direction: string) => (
                  <span
                    key={`landing-direction-${direction}`}
                    className={`line -${direction}`}
                  />
                ))
              }
            </Link>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
