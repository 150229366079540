import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import SteampunkBuildingImage from '../../images/steampunk/steampunk_render.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  SteampunkBuildingImage,
];

const ProjectSkyscraper= () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.projectSkyscraper.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Unity HDRP Renders</h2>
        <p>
          I wanted to create a polished 3D piece which could showcase my full pipeline of modelling, texuring, building,
            lighting, vfx and rendering.  I created the entire scene from scratch in Unity's High Definition Render Pipeline and used modular pieces to save time and test
            my ability to work with limited assets.  I focussed a lot on lighting and rendered both a night-time and daytime scene.  I also
            added a 'clay model' render which only shows surface details and lighting.
        </p>
        <p>
          The VFX for the machine itself is built off another piece within my portfolio 'generative art' and was used as the main focus for the scene.
          I really liked this VFX when I created it so building a fully realised scene around this was the main inspiration for the idea.  This private was created in Unity's VFX Graph.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: ImageType) => (
        <div
          key={`gen-art-energy-core-${image}`}
          className="row padding-top-img"
        >
          <div className={`${LayoutLookup.gutter.standard} text-center`}>
            <PortfolioItemImage image={image} />
          </div>
        </div>
      ))
    }
  </div>
);

export default ProjectSkyscraper;
