import React from 'react';

interface PortfolioItemYoutubeVideoProps {
  description: string,
  youtubeVideoId: string,
}

const PortfolioItemYoutubeVideo = (props: PortfolioItemYoutubeVideoProps) => {
  const {
    description,
    youtubeVideoId,
  } = props;

  const url = `https://www.youtube.com/embed/${youtubeVideoId}`;

  return (
    <iframe
      title={description}
      width="100%"
      height="473"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default PortfolioItemYoutubeVideo;
