import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import TrafficJamImage from '../../images/traffic/traffic_jam.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const TrafficJamPage = () => (
  <div>
    <div className="row">
      <div className={LayoutLookup.gutter.standard}>
        <h1>{RoutesLookup.portfolioItems.trafficJam.title}</h1>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Project Overview</h2>
        <p>
          This project was our entry for the Brains Eden 2019 Game Jam where universities from around the country come together for some friendly competition!
          The topic chosen was 'chain reaction' and we interpreted this by thinking about traffic jams and the chain reactions they can create. The whole game was create over the course of the weekend competition and features 4 player local multiplayer
          where users have to activate their coloured lights to let specific vehicles into their zone.
        </p>
        <p>
          I was creative lead on the project and worked with two other designers to ensure a coherent project both in visuals and gameplay.  The majority of my time was spent implementing assets and working on effects and post processing.
          Being the only artist who could program I was in a unique position where I could work with both sides of the team to create an effective workflow.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={TrafficJamImage}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Development Breakdown</h2>
        <p>
          After concluding the weekend I decided to dig through the repo for the project and put together a short video outlining different stages in the projects development.
          You can also find the attached thread which includes sills and text info on how each stage represented the project.  I really enjoyed creating this and it's definitely something I want to do for future projects.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <TwitterTweetEmbed
          tweetId="1146456779662004226"
        />
      </div>
    </div>
  </div>
);

export default TrafficJamPage;
