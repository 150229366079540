import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import GameCentreAppImage from '../../images/gamecentre/gamecentre_app.jpg';
import GameCentreLogoImage from '../../images/gamecentre/gamecentre_logo.jpg';
import GameCentrePosterImage from '../../images/gamecentre/gamecentre_poster.jpg';
import GameCentreWebsiteImage from '../../images/gamecentre/gamecentre_website.jpg';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';
import ImageType from '../../typings/image.type';

const footerImages: ImageType[] = [
  GameCentreWebsiteImage,
  GameCentreAppImage,
  GameCentrePosterImage
];

const GameCentrePage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.gameCentre.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Logo Design</h2>
        <p>
          This was built o the basis that the company could branch out to different games and other forms of media, the
          example I used for this particular project was Halo but this can be reproduced with any game/theme in mind.
          This project was for my final year portfolio of Graphics A-level and achieved an A grade.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={GameCentreLogoImage}
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Website and App Design</h2>
        <p>
          The main purpose of this project was to outline my UI and web development skills, here you can see the extensive
          website and app design which was also also accompanied but a set of posters; both of which can be seen as individual
          pieces of work on the website.
        </p>
      </div>
    </div>
    {
      footerImages.map((image: string) => (
        <div
          key={`game-centre-${image}`}
          className="row padding-top-img"
        >
          <div className={LayoutLookup.gutter.standard}>
            <PortfolioItemImage image={image} />
          </div>
        </div>
      ))
    }
  </div>
);

export default GameCentrePage;
