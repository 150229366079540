import React from 'react';
import ImageType from '../../../typings/image.type';

import './portfolio-item-image.scss';

interface PortfolioItemImageProps {
  image: ImageType,
  applyImageShadow?: boolean,
  caption?: string,
  className?: string,
}

const PortfolioItemImage = (props: PortfolioItemImageProps) => {
  const {
    applyImageShadow,
    className,
    image,
    caption,
  } = props;

  const classes = [
    className,
  ];
  if (applyImageShadow) {
    classes.push('img-shadow');
  }

  return (
    <div>
      <img
        className={classes.join(' ')}
        src={image}
        // TODO: Add alts for all images (descriptions for images)
        alt=""
      />
      {
        caption && <p className="text-center">{caption}</p>
      }
    </div>
  );
};

export default PortfolioItemImage;
