import React from 'react';
import InstagramLogo from '../../../images/socials/insta.png';
import TwitterLogo from '../../../images/socials/twitter.png';
import ImageType from '../../../typings/image.type';

import './social-banner.scss';

const SocialBanner = () => (
  <div>
    <div className="social">
      <SocialMediaLink
        href="https://twitter.com/jacktilling_?fbclid=IwAR0tEYS76ArNkiTn706N99GzFkhIcPv8YYNelGHTGQS1_o4HoSku7aRCUEg"
        image={TwitterLogo}
        name="Twitter Logo"
      />
      <SocialMediaLink
        href="https://www.instagram.com/jacktilling_"
        image={InstagramLogo}
        name="Instagram Logo"
      />
    </div>
  </div>
);

interface SocialMediaLinkProps {
  href: string,
  image: ImageType,
  name: string,
}

const SocialMediaLink = (props: SocialMediaLinkProps) => {
  const {
    href,
    image,
    name,
  } = props;

  return (
    <a
      target="_blank"
      href={href} rel="noreferrer"
    >
      <img
        src={image}
        alt={name}
      />
    </a>
  );
};

export default SocialBanner;
