import React from 'react';
import PortfolioItemImage from '../../components/portfolio-items/portfolio-item-image/portfolio-item-image';
import FestogoWebsiteImage from '../../images/festogo/festogo.jpg';
import FestogoLogoOne from '../../images/festogo/festogo_logo.png';
import FestogoLogoTwo from '../../images/festogo/festogo_logo_1.png';
import { LayoutLookup } from '../../lookups/layout.lookup';
import { RoutesLookup } from '../../lookups/routes.lookup';

const FestogoBrandingPage = () => (
  <div>
    <div className="row">
      <div className={`separator ${LayoutLookup.gutter.standard}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <h1>{RoutesLookup.portfolioItems.festogoBranding.title}</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Brand Design</h2>
        <p>
          Festogo is a festival comparison site and ticket retailer that started official development in January 2017.
          It's a very multi-disciplined project and I have learned a great deal of design and web development knowledge
          from it. Specifically, I feel as if my brand development and flow of user experience has improved extensively.
          As the primary designer at the start of the project it was my main concern to focus on the logo and branding as
          well as help guide the website design process.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={FestogoLogoOne}
          caption="This shows the logo development from the start of the project"
        />
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={FestogoLogoTwo}
          caption="These are the current designs used by the website"
        />
      </div>
    </div>
    <div className="row padding-top">
      <div className={LayoutLookup.gutter.standard}>
        <h2>Website Overview</h2>
        <p>
          This is just a quick overview of the current website. You can visit the live build <a target="_blank" href="https://festogo.co.uk" rel="noreferrer">here</a>.
        </p>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <iframe title="Festogo" src="https://festogo.co.uk" width="100%" height="600px"/>
      </div>
    </div>
    <div className="row padding-top-img">
      <div className={LayoutLookup.gutter.standard}>
        <PortfolioItemImage
          image={FestogoWebsiteImage}
        />
      </div>
    </div>
  </div>
);

export default FestogoBrandingPage;
